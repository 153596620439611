import { useState } from "react";
import stampede2021 from "./stampede2021.png";
import thunderBay from "./thunderbay2019.png";
import gobby from "./Gobby.png";
import christmasMarket from "./christmasMarket.png";
import babyzen from "./babyzen.png";
import whistler from "./whistler.png";
import bigWhite from "./bigWhite.png";
import goldenTrip from "./goldenTrip.png";
import RevelStoke from "./Revelstoke.png";
import kateSleeping from "./KateSleepingZen.png";
import firstPic from "./firstCouplePic.png";
import eastCoastGolf from "./eastCoast.png";
import gobbyMissing from "./Gobbymissingmama.png";
import roofr from "./roofr.png";
import firstNight from "./firstnightKelowna.png";
import kelownaBeach from "./beachKelowna.png";
import rainVideo from "./kateRain.mp4";
import pigVideo from "./meetpig.mp4";
import christmasSecret from "./christmasSecret.mp4";
import firstAccident from "./accident.png";
import buffaloTrip from "./buffalotrip.png";
import carlyWedding from "./carlywedding.png";
import carlyBryceDate from "./carlybrycedate.png";
import chrisNatalia from "./chrisnatalia.png";
import cottyWisc from "./cotty.png";
import cruisePic from "./cruise.png";
import snowTube from "./snowtube.png";
import sonsofthe from "./sonsofthe.mp4";
import surpriseParty from "./supriseparty.mp4";
import themeDinner from "./themedinner.png";
import vanGogh from "./vangogh.png";
import visitHome from "./visithomebeforemove.mp4";
import whistlerBar from "./whistlerbars.mp4";
import winery from "./winery.png";
import wineTasting from "./winetastin.png";
import escape from "./escape.png";
import firstApartment from "./firstapartmentsetup.png";
import firstStory from "./firststory.png";
import firstTripHome from "./firsttriphome.png";
import valentines from "./firstvalentines.mp4";
import g2Party from "./g2party.mp4";
import gingerbread from "./gingerbread.png";
import greece from "./greece.png";
import kelownaDate from "./kelownaDate.png";
import lakeLouise from "./lakelouise.png";
import lastKelowna from "./LastnightinKelowna.png";
import longDistanceVisit from "./longdistancetriptoronto.mp4";
import bestamore from "./meetingbestamore.png";
import margs from "./mrgs.png";
import parentTrip from "./parentstrip.png";
import "./randomizer.css";

function Randomizer() {
  const memories = [
    {
      name: "Calgary Stampede",
      location: "Calgary Alberta",
      date: "July 2022",
      img: stampede2021,
      moment: "Pre's at the guys Air Bnb",
      moment2: "Wildhorse Saloon Tent",
      containVideo: "false",
    },
    {
      name: "Cousin Rachel's Wedding",
      location: "Thunder Bay",
      date: "August 2019",
      img: thunderBay,
      moment: '"Jesus christ Al!"',
      containVideo: "false",
    },
    {
      name: "Getting to know Gobby",
      location: "Hockley Valley",
      date: "November 2021",
      img: gobby,
      moment: "Gobby's nail getting stuck in Kate's sweater",
      containVideo: "false",
    },
    {
      name: "Baby Zen",
      location: "Hockley Valley",
      date: "December 2020",
      moment: "Zen hopping in Kate's lap",
      img: babyzen,
      containVideo: "false",
    },
    {
      name: "Toronto Christmas Market",
      location: "Distillery District Toronto",
      date: "December 2019",
      moment:
        "Debating if the boys would go to war if our country called. Boys said yes, Kate said no.",
      img: christmasMarket,
      containVideo: "false",
    },
    {
      name: "Whistler Ski Trip",
      location: "Whistler, BC",
      date: "Febraury 2023",
      moment: "Uncrustables",
      moment2:
        " Competitive Sirko going mute for two hour card game and losing",
      img: whistler,
      containVideo: "false",
    },
    {
      name: "First Time Skiing At Big White",
      location: "Big White Ski Resort",
      date: "January 2020",
      moment: "Kate Skiing her first full size mountain",
      img: bigWhite,
      containVideo: "false",
    },
    {
      name: "Revelstoke Trip with the Hunts",
      location: "Revelstoke BC",
      date: "September 2021",
      moment: "Spending time with family and friends",
      img: RevelStoke,
      containVideo: "false",
    },
    {
      name: "Golden Trip With West Coast Friends",
      location: "Golden BC",
      date: "May 2020",
      moment: " 'If I was pregnant the baby would be dead' ",
      moment2: "Horse Show + Pregnant Cat",
      img: goldenTrip,
      containVideo: "false",
    },
    {
      name: "Moving back home",
      location: "Hockley Valley",
      date: "December 2022",
      moment: "Spending time with the dogs",
      moment2: "Not being broke",
      img: kateSleeping,
      containVideo: "false",
    },
    {
      name: "First couple picture",
      location: "Distillery District, Toronto",
      date: "December 2018",
      moment: "Became insta official!!!",
      moment2: "Got to know the union 1.0",
      img: firstPic,
      containVideo: "false",
    },
    {
      name: "Emma and Conrad East Coast Trip",
      location: "Pugwash, Nova Scotia",
      date: "July 28th 2022",
      moment: "Striped some shots with the fellas",
      moment2: "Stargazing on the front yard",
      img: eastCoastGolf,
      containVideo: "false",
    },
    {
      name: "Gobby missing Kate",
      location: "Hockley Valley",
      date: "December 2022",
      moment: "Ma ma Ma ma",
      img: gobbyMissing,
      containVideo: "false",
    },
    {
      name: "Crushing it at Roofr",
      location: "Hockley Valley",
      date: "April 2022",
      moment: "Stacking Promotions",
      moment2: "Hey Richard do you care when guys...",
      img: roofr,
      containVideo: "false",
    },
    {
      name: "First night in kelowna",
      location: "Mission Springs Drive, Kelowna",
      date: "November 2019",
      moment: "Spending the night in 'our place' for the first time",
      img: firstNight,
      containVideo: "false",
    },
    {
      name: "Nights at the Beach in Kelowna",
      location: "Rotary Beach Park, Kelowna",
      date: "December 2019",
      moment: "Reading books sitting in the grass",
      moment2: "Bringing the picnic basket and listening to music",
      img: kelownaBeach,
      containVideo: "false",
    },
    {
      name: "Enjoying the rain",
      location: "Hockley Valley",
      date: "June 2023",
      moment: "🌧️🌧️🌧️",
      video: rainVideo,
      containVideo: "true",
    },
    {
      name: "Meeting the pig",
      location: "Hockley Valley",
      date: "August 2019",
      video: pigVideo,
      containVideo: "true",
      moment: "'Would he attack me?'",
    },
    {
      name: "Secret Christmas Bar",
      location: "Santas Workshop",
      date: "December 2018",
      video: christmasSecret,
      containVideo: "true",
      moment: "Immediately fought afterwards",
    },
    {
      name: "First car accident",
      date: "November 2021",
      location: "Veterns way road",
      containVideo: "false",
      img: firstAccident,
      moment: "Not having insurance in the car",
      moment2: "Not being able to use a car for months",
    },
    {
      name: "Quailsgate 'wine tasting'",
      date: "June 2020",

      location: "Kelowna, BC",
      containVideo: "false",
      moment: "Kate's chug",
      img: winery,
    },
    {
      name: "Mission Hill Winery",
      date: "July 2021",

      location: "Kelowna, BC",
      containVideo: "false",
      moment: "Get buzzed at the tasting",

      img: wineTasting,
    },
    {
      name: "Longhorn Tavern",
      date: "January 2023",
      location: "Whistler, BC",
      containVideo: "true",
      moment: "First apres ski of the trip",
      video: whistlerBar,
    },
    {
      name: "Kate's parents visit Ontario",
      location: "Hockley Valley",
      date: "September 2023",
      moment: "Golfing with your dad",
      moment2: "Family dinners",
      img: parentTrip,
      containVideo: "false",
    },
    {
      name: "Margs on the apartment patio",
      location: "Bathurst Street, Toronto, Ontario",
      date: "April 2022",
      moment: "Kate 'reading books' downing 3oz margharitas",
      img: margs,
      containVideo: "false",
    },
    {
      name: "Visiting Orangeville before moving West",
      location: "Hockley Valley",
      moment: "Taking this pic",
      containVideo: "true",
      date: "May 2019",
      video: visitHome,
    },
    {
      name: "Van Gogh Exhibit",
      location: "Toronto",
      moment: "Seeing cools fits in the crowd",
      containVideo: "false",
      date: "October 2022",
      img: vanGogh,
    },
    {
      name: "Covid Theme Dinners",
      location: "Kelowna, BC",
      moment: "Trying to stay mentally sane",
      containVideo: "false",
      date: "April 2020",
      img: themeDinner,
    },
    {
      name: "Moving to BC surprise party",
      location: "Elizabeth Street, Toronto",
      moment: "Great send off with all our close friends!",
      video: surpriseParty,
      containVideo: "true",
      date: "May 2019",
    },
    {
      name: "Trip to buffalo",
      location: "Buffalo, New York",
      moment: "Intense escape rooms with Robbie",
      moment2: "Buffalo Wings and french onion soup",
      date: "October 2023",
      containVideo: "false",
      img: buffaloTrip,
    },
    {
      name: "Carly ands Bryce's Wedding",
      date: "August 2023",
      location: "Cape Breton, Nova Scotia",
      moment: "Getting to know Igor",
      moment2: "I am a guest of Lesley Bland",
      containVideo: "false",
      img: carlyWedding,
    },
    {
      name: "Date with Carly and Bryce before moving",
      date: "October 2023",
      location: "Toronto, Ontario",
      moment: "Good food at Piano Piano",
      containVideo: "false",
      img: carlyBryceDate,
    },
    {
      name: "Chris and Natalia's Wedding",
      date: "July 2022",
      location: "First Line, Mono, Ontario",
      moment: "Owning the dance floor",
      moment2: "Natalia getting dinged",
      containVideo: "false",
      img: chrisNatalia,
    },
    {
      name: "Cotty 2020",
      date: "July 2020",
      location: "Little Bull Island, Athens, Ontario",
      moment: "Finally getting a break from lockdowns",
      containVideo: "false",
      img: cottyWisc,
    },
    {
      name: "Cruise (first vacation together)",
      date: "January 2020",
      location: "Somewhere in the Caribbean",
      moment: "Taking small charter boat out in St.Thomas",
      containVideo: "false",
      img: cruisePic,
    },
    {
      name: "Snowtubing",
      date: "March 2023",
      location: "HorseShoe Valley Ski Resort",
      moment: "Going down the biggest hill",
      containVideo: "false",
      img: snowTube,
    },
    {
      name: "Sons of the east concert",
      date: "September 2023",
      location: "The opera house, Toronto",
      moment: "All the girls thinking the lead singer was checking them out",
      containVideo: "true",
      video: sonsofthe,
    },
    {
      name: "First escape room with Rob and Laura",
      date: "October 2023",
      location: "Toronto, Ontario",
      moment: "Do not bite anything",
      moment2: "The Bills smoked the commanders right after the escape room",
      containVideo: "false",
      img: escape,
    },
    {
      name: "First aparatment setup",
      date: "December 2019",
      location: "201 Mission Springs Drive, Kelowna",
      moment: "The rent being $1450 😭",
      containVideo: "false",
      img: firstApartment,
    },
    {
      name: "First appearance on Kate's Insta",
      date: "October 2018",
      location: "A Pho restuarant somewhere in Toronto",
      moment: "Nathan got to feel very mysterious",
      containVideo: "false",
      img: firstStory,
    },
    {
      name: "First trip to the East Coast",
      location: "Pugwash, Nova Scotia",
      date: "August 2021",
      moment: "Meeting Kate's family and seeing where she was from",
      moment2: "Thai food and sushi",
      containVideo: "false",
      img: firstTripHome,
    },
    {
      name: "First Valentines day together",
      date: "February 14th, 2019",
      location: "Kate's Apartment, Toronto, Ontario",
      moment: "Being happy :)",
      containVideo: "true",
      video: valentines,
    },
    {
      name: "Sirko's G2 Party",
      date: "July 2023",
      location: "Clancy Island, Athens, Ontario",
      moment: "Dale bringing sparklers for this",
      containVideo: "true",
      video: g2Party,
    },
    {
      name: "Making first Gingerbread house",
      location: "Kate's Aparatment, Toronto",
      date: "December 2018",
      moment: "Eating the tastiest building supplies",
      containVideo: "false",
      img: gingerbread,
    },
    {
      name: "Jordan and Cory's Greek Wedding",
      date: "August 2023",
      location: "Corfu, Greece",
      moment: "Cruise day with dance party on way home",
      containVideo: "false",
      img: greece,
    },
    {
      name: "Kelowna Dates",
      date: "June 2021",
      location: "Downtown Kelowna, BC",
      moment: "Trying all the restuarants and getting out of the house",
      containVideo: "false",
      img: kelownaDate,
    },
    {
      name: "Trip to Lake Louise With Pete and Lauren",
      date: "February 2021",
      location: "Lake Louise, Alberta",
      moment: "Spending time with Pete and Lauren",
      containVideo: "false",
      img: lakeLouise,
    },
    {
      name: "Last night in Kelowna",
      date: "October 2021",
      location: "Rotary Park Beach, Kelowna, BC",
      moment: "Saying bye to our favourite spot in town",
      containVideo: "false",
      img: lastKelowna,
    },
    {
      name: "First long distance visit",
      date: "July 2019",
      location: "Kates Apartment, Toronto, Ontario",
      moment: "Finally seeing eachother again!!!",
      containVideo: "true",
      video: longDistanceVisit,
    },
    {
      name: "Meeting Bestamore",
      date: "August 2021",
      location: "Bestamor's House, Nova Scotia",
      moment: "Hearing Uncle Andrew's Stories",
      moment2: "Getting to know Kates family",
      containVideo: "false",
      img: bestamore,
    },
  ];

  const [memory, setMemory] = useState({
    name: "First couple picture",
    location: "Distillery District, Toronto",
    date: "December 2018",
    moment: "Became insta official!!!",
    moment2: "Got to know the union 1.0",
    img: firstPic,
    containVideo: "false",
  });

  const [num, setNum] = useState(0);

  function getMemory() {
    setNum(Math.floor(Math.random() * (51 - 1)));
    console.log(num);

    setMemory({
      name: memories[num].name,
      date: memories[num].date,
      location: memories[num].location,
      img: memories[num].img,
      moment: memories[num].moment,
      moment2: memories[num].moment2,
      video: memories[num].video,
      containvideo: memories[num].containVideo,
    });

    displayMemory(memory);
  }

  function displayMemory(memory) {
    console.log(memory.containvideo);
    console.log(memory.name);

    return <></>;
  }

  return (
    <>
      {memory.containvideo === "true" ? (
        <div className="content">
          <div className="polaroid">
            <div className="information">
              <div className="title">
                <h1>{memory.name}</h1>
              </div>
              <div className="dateTime">
                <div className="date">
                  <h4>📅 {memory.date}</h4>
                </div>
                <div className="location">
                  <h4>📍 {memory.location}</h4>
                </div>
              </div>

              <div className="momentDescription">
                <h3>Memorable Moments:</h3>
                <h4 className="moment">{memory.moment}</h4>
                <h4 className="moment">{memory.moment2}</h4>
              </div>
            </div>
            <div className="imageDiv">
              <video className="theVid" src={memory.video} controls />
            </div>
          </div>

          <button className="memoryButton" onClick={getMemory}>
            Find a Nate and Kate memory
          </button>
        </div>
      ) : (
        <div className="content">
          <div className="polaroid">
            <div className="information">
              <div className="title">
                <h1>{memory.name}</h1>
              </div>
              <div className="dateTime">
                <div className="date">
                  <h4>📅 {memory.date}</h4>
                </div>
                <div className="location">
                  <h4>📍 {memory.location}</h4>
                </div>
              </div>

              <div className="momentDescription">
                <h3>Memorable Moments:</h3>
                <h4 className="moment">{memory.moment}</h4>
                <h4 className="moment">{memory.moment2}</h4>
              </div>
            </div>
            <div className="imageDiv">
              <img className="image" alt="memory" src={memory.img}></img>
            </div>
          </div>
          <button className="memoryButton" onClick={getMemory}>
            Find a Nate and Kate memory
          </button>
        </div>
      )}
    </>
  );
}

export default Randomizer;
