import "./App.css";
import Randomizer from "./components/randomizer";
import { useState } from "react";

function App() {
  const [clicked, setClicked] = useState("false");

  function handleClick() {
    setClicked("true");
  }

  return (
    <div className="App">
      {clicked === "true" ? (
        <Randomizer />
      ) : (
        <>
          <h3>Welcome to memory lane...</h3>
          <h3>
            {" "}
            Click the button to check out five years of awesome memories.
          </h3>
          <button className="theBtn" onClick={handleClick}>
            Get Started
          </button>
        </>
      )}
    </div>
  );
}

export default App;
